import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          Hi Everyone, I am <span className="purple">Vesta Tian</span> from 
          <span className="purple"> Singapore </span>.
          <br />
          I am currently working at <span className="purple">E Tune Works</span>,  passionate about creating solutions that bring ease and efficiency through automation.
          <br />
          I find inspiration in the simplicity of nature and strive to integrate that sense of tranquility and comfort into my work.
          <br />
          <br />
          When I'm not coding, here are a few activities I enjoy!
        </p>
        <ul>
          <li className="about-activity">
            <ImPointRight /> Exploring Nature
          </li>
          <li className="about-activity">
            <ImPointRight /> Designing Comfortable Living Spaces
          </li>
          <li className="about-activity">
            <ImPointRight /> Automating Everyday Tasks
          </li>
        </ul>

        <p style={{ color: "rgb(155 126 172)" }}>
          "Wee woo!"
        </p>
        <footer className="blockquote-footer">Vesta</footer>

        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
