// Tree of outgoing links to keep them updated

const OutgoingLinks = {
  Github: "https://github.com/VestaCord/",
  LinkedIn: "https://sg.linkedin.com/in/vesta-tian-128181240",
  Blog: "https://dev.eaunique.asia/blog/",
  Twitter: "",
  Instagram: "",
  // Prisonner's Dilemma Game Links
  Proj_PD_gh:"",
  Proj_PD_demo:"",
};

export default OutgoingLinks;