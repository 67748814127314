import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import avatar from "../../Assets/avatar.png";
import Tilt from "react-parallax-tilt";
import OutgoingLinks from "../Links";
import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              LET ME <span className="purple"> INTRODUCE </span> MYSELF
            </h1>
            <p className="home-about-body">
            My journey in programming has been shaped by hands-on experience as the Technical Head at E Tune, where I led the development of AI models and innovative solutions.
            <br />
            <br />I am proficient in
            <i>
              <b className="purple"> TypeScript and Python, </b>
            </i>
            honed through a mix of product development, Arduino projects, and advanced web applications.
            <br />
            <br />
            My interests lie in pushing the boundaries of 
            <i>
              <b className="purple">Web Technologies and Products,</b>
            </i>
            with a particular focus on integrating AI and emerging technologies like{" "}
            <b className="purple">Blockchain.</b>
            <br />
            <br />
            With experience as part of a satellite development team, I can apply my problem skills to create any applications using <b className="purple">Node.js</b> and
            <i>
              <b className="purple"> modern JavaScript frameworks</b>
            </i>
            &nbsp; such as
            <i>
              <b className="purple"> React.js and Next.js.</b>
            </i>
          </p>

          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={avatar} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href={OutgoingLinks.Github}
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href={OutgoingLinks.Twitter}
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href={OutgoingLinks.LinkedIn}
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href={OutgoingLinks.Instagram}
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
